export const employee_type = ['स्थाई', 'अस्थाई', 'करार', 'ज्यालादारी'];
export const employee_level = ['करार', 'प्रथम', 'दितिय', 'तृतीय', 'चौथो', 'पाँचौ', 'वरिस्ठ पाँचौ'];
export const employee_designation = [
	'व्यवस्थापक',
	'स.व्यवस्थापक',
	'लेखापाल',
	'प्राबिधिक',
	'सिनियर डेरी ब्योई',
	'डेरी ब्योई',
	'कार्यालय सहयोगी',
	'सफाई सहयोगी',
];

export const NP_UPTO_HUNDREDS = [
	'शून्य',
	'एक',
	'दुई',
	'तीन',
	'चार',
	'पाँच',
	'छ',
	'सात',
	'आठ',
	'नौ',
	'दश',
	'एघार',
	'बाह्र',
	'तेह्र',
	'चौध',
	'पन्ध्र',
	'सोह्र',
	'सत्र',
	'अठार',
	'उन्नाइस',
	'बीस',
	'एक्काइस',
	'बाइस',
	'त्येइस',
	'चौबीस',
	'पच्चीस',
	'छब्बीस',
	'सत्ताइस',
	'अठ्ठाइस',
	'उनन्तीस',
	'तीस',
	'एक्तीस',
	'बत्तीस',
	'तेत्तिस',
	'चौंतीस',
	'पैंतीस',
	'छत्तीस',
	'सर्तीस',
	'अर्तीस',
	'उनन्चालीस',
	'चालीस',
	'एकचालीस',
	'बयालीस',
	'त्रिचालीस',
	'चवालीस',
	'पैंतालीस',
	'छयालीस',
	'सर्चालीस',
	'अर्चालीस',
	'उनन्चास',
	'पचास',
	'एकाउन्न',
	'बाउन्न',
	'त्रिपन्न',
	'चौवन्न',
	'पचपन्न',
	'छ्प्पन्न',
	'सन्ताउन्न',
	'अन्ठाउन्न',
	'उनान्साठ्ठी',
	'साठ्ठी',
	'एकसट्ठी',
	'बैसट्ठी',
	'त्रिसट्ठी',
	'चौंसठ्ठी',
	'पैंसठ्ठी',
	'छैंसठ्ठी',
	'सर्सठ्ठी',
	'अर्सठ्ठी',
	'उनन्सत्तरी',
	'सत्तरी',
	'एकत्तर',
	'बहत्तर',
	'त्रीहत्तर',
	'चौरत्तर',
	'पचहत्तर',
	'छयत्तर',
	'सतहत्तर',
	'अठहत्तर',
	'उनास्सी',
	'अस्सी',
	'एकास्सी',
	'बयास्सी',
	'त्रियास्सी',
	'चैरस्सी',
	'पचास्सी',
	'छायास्सी',
	'सत्तास्सी',
	'अठास्सी',
	'उनन्नाब्बे',
	'नब्बे',
	'एकान्नब्बे',
	'बयान्नब्बे',
	'त्रियान्नब्बे',
	'चौरान्नब्बे',
	'पन्चान्नब्बे',
	'छायान्नब्बे',
	'सन्तान्नब्बे',
	'अन्ठान्नब्बे',
	'उनन्सय',
];

/**
 * Nepali Tenth
 */
export const NP_TENS = {
	1: 'एक',
	10: 'दश',
	100: 'सय',
	1000: 'हजार',
	100000: 'लाख',
	10000000: 'करोड',
	1000000000: 'अर्ब',
	100000000000: 'खर्ब',
};

//Nepali Minus
export const NP_MINUS = 'माइनस';
//Nepali Decimal Point
export const NP_POINT = 'दशमलब';

//Maximum Supported number
export const MAX_SUPPORTED_NUMBER = 100000000000 * 100 - 1;

// Nepali Money Strings
export const NP_RUPEES = 'रूपैयाँ';
export const NP_PAISA = 'पैसा';

// Avaliable word formats
export const WORD_FORMAT = {
	TEXT: 'text',
	MONEY: 'money',
};
