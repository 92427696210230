const stationaryRuleCheck = ({ has_stationery_permissions }) => {
	return has_stationery_permissions;
};
const RepairRuleCheck = ({ has_repair_permissions }) => {
	return has_repair_permissions;
};
const DailySalesRuleCheck = ({ has_repair_permissions }) => {
	return has_repair_permissions;
};
const ChallanRuleCheck = ({ has_challan_permissions }) => {
	return has_challan_permissions;
};
const rules = {
	user: {
		dynamic: {
			'add:stationaryItem': stationaryRuleCheck,
			'edit:stationaryItem': stationaryRuleCheck,
			'view:stationaryItem': stationaryRuleCheck,
			'add:stationaryBill': stationaryRuleCheck,
			'edit:stationaryBill': stationaryRuleCheck,
			'view:stationaryBill': stationaryRuleCheck,
			'add:repairItem': RepairRuleCheck,
			'edit:repairItem': RepairRuleCheck,
			'view:repairItem': RepairRuleCheck,
			'add:repairBill': RepairRuleCheck,
			'edit:repairBill': RepairRuleCheck,
			'view:repairBill': RepairRuleCheck,
			'add:dailySales': DailySalesRuleCheck,
			'edit:dailySales': DailySalesRuleCheck,
			'view:dailySales': DailySalesRuleCheck,
			'create:challan': ChallanRuleCheck,
			'view:challan': ChallanRuleCheck,
		},
	},
	superadmin: {
		static: [
			'add:stationaryItem',
			'edit:stationaryItem',
			'view:stationaryItem',
			'add:stationaryBill',
			'edit:stationaryBill',
			'view:stationaryBill',
			'add:repairItem',
			'edit:repairItem',
			'view:repairItem',
			'add:repairBill',
			'edit:repairBill',
			'view:repairBill',
			'add:dailySales',
			'edit:dailySales',
			'view:dailySales',
			'crud:industry',
			'crud:chillingcenter',
			'crud:cooperative',
			'view:settingdropdown',
			'view:sanghprofile',
			'view:report',
			'add:challan',
			'view:challan',
			'edit:challan',
			'delete:challan',
		],
	},
};

export default rules;
