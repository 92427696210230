import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	payments: [],
	count: 0,
	page: 1,
};

export default function (state = initialState, action) {
	let index, tempPayment;
	switch (action.type) {
		case ActionTypes.SET_ALL_PAYMENTS:
			return {
				...state,
				payments: action.payload.response.results,
				count: action.payload.response.count,
				page: action.payload.page,
			};
		case ActionTypes.SET_NEW_PAYMENT:
			tempPayment = [...state.payments];
			index = tempPayment.findIndex((pay) => pay.id === action.payload.id);
			if (index !== -1) {
				tempPayment[index] = action.payload;
				return { ...state, payments: tempPayment };
			} else {
				tempPayment.push(action.payload);
				return { ...state, payments: tempPayment, count: state.count + 1 };
			}
		case ActionTypes.REMOVE_PAYMENT:
			tempPayment = [...state.payments];
			index = tempPayment.findIndex((pay) => pay.url === action.payload);
			tempPayment.splice(index, 1);
			return { ...state, payments: tempPayment, count: state.count - 1 };

		case ActionTypes.LOADING_PAYMENT:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_PAYMENT:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
