//authentication types
export const SET_USER = 'SET_USER';
export const SET_NEW_USER = 'SET_NEW_USER';
export const REMOVE_DELETED_USER = 'REMOVE_DELETED_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const CLEAR_USER_LOADING = 'CLEAR_USER_LOADING';
export const SET_LOGGEDIN = 'SET_LOGGEDIN';
export const SET_LOGGEDOUT = 'SET_LOGGEDOUT';
export const SET_ALL_USER = 'SET_ALL_USER';
export const SET_TODAYS_DATE = 'SET_TODAYS_DATE';

// user interface types
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SET_SANGH = 'SET_SANGH';

//chilling centers types
export const SET_ALL_CHILLING_CENTERS = 'SET_ALL_CHILLING_CENTERS';
export const SET_NEW_CHILLING_CENTER = 'SET_NEW_CHILLING_CENTER';
export const REMOVE_CHILLING_CENTER = 'REMOVE_CHILLING_CENTER';
export const LOADING_CHILLING_CENTER = 'LOADING_CHILLING_CENTER';
export const STOP_LOADING_CHILLING_CENTER = 'STOP_LOADING_CHILLING_CENTER';

//cooperatives types
export const SET_ALL_COOPERATIVES = 'SET_ALL_COOPERATIVES';
export const SET_NEW_COOPERATIVE = 'SET_NEW_COOPERATIVE';
export const REMOVE_COOPERATIVE = 'REMOVE_COOPERATIVE';
export const LOADING_COOPERATIVE = 'LOADING_COOPERATIVE';
export const STOP_LOADING_COOPERATIVE = 'STOP_LOADING_COOPERATIVE';

//industries types
export const SET_ALL_INDUSTRIES = 'SET_ALL_INDUSTRIES';
export const SET_NEW_INDUSTRY = 'SET_NEW_INDUSTRY';
export const REMOVE_INDUSTRY = 'REMOVE_INDUSTRY';
export const LOADING_INDUSTRY = 'LOADING_INDUSTRY';
export const STOP_LOADING_INDUSTRY = 'STOP_LOADING_INDUSTRY';

//chalan types
export const SET_ALL_CHALANS = 'SET_ALL_CHALANS';
export const SET_NEW_CHALAN = 'SET_NEW_CHALAN';
export const REMOVE_CHALAN = 'REMOVE_CHALAN';
export const LOADING_CHALAN = 'LOADING_CHALAN';
export const STOP_LOADING_CHALAN = 'STOP_LOADING_CHALAN';

//schedule types
export const SET_ALL_SCHEDULES = 'SET_ALL_SCHEDULES';
export const SET_NEW_SCHEDULE = 'SET_NEW_SCHEDULE';
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE';
export const LOADING_SCHEDULE = 'LOADING_SCHEDULE';
export const STOP_LOADING_SCHEDULE = 'STOP_LOADING_SCHEDULE';

// setting types
export const SET_NEW_RATE = 'SET_NEW_RATE';
export const REMOVE_RATE = 'REMOVE_RATE';
export const SET_ALL_RATE = 'SET_ALL_RATE';
export const SET_FISCAL_YEARS = 'SET_FISCAL_YEARS';
export const LOADING_FISCAL_YEARS = 'LOADING_FISCAL_YEARS';
export const STOP_LOADING_FISCAL_YEARS = 'STOP_LOADING_FISCAL_YEARS';

export const POST_FISCAL_YEAR = 'POST_FISCAL_YEAR';
export const EDIT_FISCAL_YEAR = 'EDIT_FISCAL_YEAR';

//payment types
export const SET_ALL_PAYMENTS = 'SET_ALL_PAYMENTS';
export const SET_NEW_PAYMENT = 'SET_NEW_PAYMENT';
export const REMOVE_PAYMENT = 'REMOVE_PAYMENT';
export const LOADING_PAYMENT = 'LOADING_PAYMENT';
export const STOP_LOADING_PAYMENT = 'STOP_LOADING_PAYMENT';

//language types
export const SET_LANGUAGE = 'SET_LANGUAGE';

//repair and stationary
export const SET_REPAIR_LIST = 'SET_REPAIR_LIST';
export const SET_STATIONARY_LIST = 'SET_STATIONARY_LIST';
export const REMOVE_REPAIR = 'REMOVE_REPAIR';
export const REMOVE_STATIONARY = 'REMOVE_STATIONARY';
export const UPDATE_REPAIR = 'UPDATE_REPAIR';
export const UPDATE_STATIONARY = 'UPDATE_STATIONARY';
export const LOADING_STATIONARY = 'LOADING_STATIONARY';
export const STOP_LOADING_STATIONARY = 'STOP_LOADING_STATIONARY';
export const LOADING_REPAIR = 'LOADING_REPAIR';
export const STOP_LOADING_REPAIR = 'STOP_LOADING_REPAIR';

// Account
// Main Group
export const GET_ALL_MAIN_GROUP = 'GET_ALL_MAIN_GROUP';

// Ledger Group
export const GET_ALL_LEDGER_GROUP = 'GET_ALL_LEDGER_GROUP';
export const POST_LEDGER_GROUP = 'POST_LEDGER_GROUP';
export const DELETE_LEDGER_GROUP = 'DELETE_LEDGER_GROUP';
export const EDIT_LEDGER_GROUP = 'EDIT_LEDGER_GROUP';
export const LOADING_LEDGER_GROUP = 'LOADING_LEDGER_GROUP';
export const STOP_LOADING_LEDGER_GROUP = 'STOP_LOADING_LEDGER_GROUP';

// Subledger
export const GET_ALL_SUB_LEDGER = 'GET_ALL_SUB_LEDGER';
export const POST_SUB_LEDGER = 'POST_SUB_LEDGER';
export const DELETE_SUB_LEDGER = 'DELETE_SUB_LEDGER';
export const EDIT_SUB_LEDGER = 'EDIT_SUB_LEDGER';
export const LOADING_SUB_LEDGER = 'LOADING_SUB_LEDGER';
export const STOP_LOADING_SUB_LEDGER = 'STOP_LOADING_SUB_LEDGER';

// General Ledger
export const GET_ALL_LEDGER = 'GET_ALL_LEDGER';
export const LOADING_LEDGER = 'LOADING_lEDGER';
export const STOP_lOADING_LEDGER = 'STOP_lOADING_LEDGER';
export const POST_LEDGER = 'POST_LEDGER';
export const DELETE_LEDGER = 'DELETE_LEDGER';
export const EDIT_LEDGER = 'EDIT_LEDGER';

// Voucher
export const GET_ALL_VOUCHER = 'GET_ALL_VOUCHER';
export const POST_VOUCHER = 'POST_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const EDIT_VOUCHER = 'EDIT_VOUCHER';
export const LOADING_VOUCHER = 'LOADING_VOUCHER';
export const STOP_LOADING_VOUCHER = 'STOP_LOADING_VOUCHER';

//closing types
export const GET_ALL_CLOSING_STOCK = 'GET_ALL_CLOSING_STOCK';
export const POST_CLOSING_STOCK = 'POST_CLOSING_STOCK';
export const DELETE_CLOSING_STOCK = 'DELETE_CLOSING_STOCK';

// Report
export const SET_TRIALBALANCE_DATA = 'SET_TRIALBALANCE_DATA';
export const SET_BALANCESHEET_DATA = 'SET_BALANCESHEET_DATA';
export const SET_INCOME_STATEMENT = 'SET_INCOME_STATEMENT';
export const SET_LEDGER_DATA = 'SET_LEDGER_DATA';
export const LOADING_BALANCE_SHEET = 'LOADING_BALANCE_SHEET';
export const STOP_LOADING_BALANCE_SHEET = 'STOP_LOADING_BALANCE_SHEET';
export const LOADING_TRIALBALANCE = 'LOADING_TRIALBALANCE';
export const STOP_LOADING_TRIALBALANCE = 'LOADING_TRIALBALANCE';

// Employee

export const GET_ALL_EMPLOYEE = 'GET_ALL_EMPLOYEE';
export const GET_EMPLOYEE_PROFILE = 'GET_EMPLOYEE_PROFILE';

export const SET_NEW_EMPLOYEE = 'SET_NEW_EMPLOYEE';
export const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';
export const LOADING_EMPLOYEE = 'LOADING_EMPLOYEE';
export const STOP_LOADING_EMPLOYEE = 'STOP_LOADING_EMPLOYEE';
export const GET_ALL_DESIGNATION = 'GET_ALL_DESIGNATION';
export const GET_ALL_EMP_TYPE = 'GET_ALL_EMP_TYPE';
//user
export const LOADING_USER = 'LOADING_USER';
export const STOP_LOADING_USER = 'STOP_lOADING_USER';
export const SET_USER_FISCAL = 'SET_USER_FISCAL';
