import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	rates: [],
	fiscalYears: [],
};

export default function (state = initialState, action) {
	let tempRates, index, tempFiscalYears;
	switch (action.type) {
		case ActionTypes.SET_ALL_RATE:
			return {
				...state,
				rates: [...action.payload],
			};
		case ActionTypes.SET_NEW_RATE:
			return {
				...state,
				rates: [action.payload, ...state.rates],
			};

		case ActionTypes.REMOVE_RATE:
			tempRates = [...state.rates];
			index = tempRates.findIndex((chalan) => chalan.id === action.payload);
			tempRates.splice(index, 1);
			return { ...state, rates: tempRates };

		case ActionTypes.SET_FISCAL_YEARS:
			return {
				...state,
				fiscalYears: [...action.payload],
			};

		case ActionTypes.POST_FISCAL_YEAR:
			tempFiscalYears = [...state.fiscalYears];
			if (action.payload.active && tempFiscalYears.length) {
				tempFiscalYears = tempFiscalYears.map((year) => ({ ...year, active: false }));
			}
			return { ...state, fiscalYears: [action.payload, ...tempFiscalYears] };

		case ActionTypes.EDIT_FISCAL_YEAR:
			let tempFiscalYear = [...state.fiscalYears];
			index = tempFiscalYear.findIndex((fiscal) => fiscal.id === action.payload.id);
			if (index !== -1) {
				tempFiscalYear = tempFiscalYear.map((year) => ({ ...year, active: false }));
				tempFiscalYear[index] = action.payload;
			}
			return { ...state, fiscalYears: tempFiscalYear };
		case ActionTypes.LOADING_FISCAL_YEARS:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_FISCAL_YEARS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
