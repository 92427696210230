import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	ledgergroup: [],
	totalResults: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ALL_LEDGER_GROUP:
			return { ...state, ledgergroup: action.payload.results, totalResults: action.payload.count };
		case ActionTypes.POST_LEDGER_GROUP:
			return {
				...state,
				ledgergroup: [action.payload, ...state.ledgergroup],
				totalResults: state.totalResults + 1,
			};
		case ActionTypes.DELETE_LEDGER_GROUP:
			const tempLedgerGroup = [...state.ledgergroup];
			const index = tempLedgerGroup.findIndex((group) => group.id === action.payload);
			let totalLedgerGroup = state.totalResults;
			if (index !== -1) {
				tempLedgerGroup.splice(index, 1);
				totalLedgerGroup = totalLedgerGroup - 1;
			}
			return { ...state, ledgergroup: tempLedgerGroup, totalResults: totalLedgerGroup };
		case ActionTypes.LOADING_LEDGER_GROUP:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_LEDGER_GROUP:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
