import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	ledger: [],
	totalResults: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ALL_LEDGER:
			return { ...state, ledger: action.payload.results, totalResults: action.payload.count };
		case ActionTypes.POST_LEDGER:
			return { ...state, ledger: [action.payload, ...state.ledger], totalResults: state.totalResults + 1 };
		case ActionTypes.DELETE_LEDGER:
			const tempLedger = [...state.ledger];
			const index = tempLedger.findIndex((group) => group.id === action.payload);
			let totalLedger = state.totalResults;

			if (index !== -1) {
				tempLedger.splice(index, 1);
				totalLedger = totalLedger - 1;
			}
			return { ...state, ledger: tempLedger, totalResults: totalLedger };
		case ActionTypes.LOADING_LEDGER:
			return { ...state, loading: true };
		case ActionTypes.STOP_lOADING_LEDGER: {
			return { ...state, loading: false };
		}

		default:
			return state;
	}
}
