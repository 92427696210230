import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const loginUser = (user, history) => async (dispatch) => {
	try {
		const response = await fetchWrapper.post(COMMON_URL + `auth/token/login/`, user);
		localStorage.setItem('token', response.auth_token);
		dispatch({ type: ActionTypes.SET_LOGGEDIN });
		history.push('/');
	} catch (e) {
		console.log(e);
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: e.detail },
		});
	}
};

export const logoutUser = () => async (dispatch) => {
	try {
		localStorage.removeItem('token');
		dispatch({ type: ActionTypes.SET_LOGGEDOUT });
		await fetchWrapper.post(COMMON_URL + `auth/token/login/`);
	} catch (e) {
		console.log(e);
	}
};
