import * as Yup from 'yup';

export const regValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});
export const logValidationSchema = Yup.object().shape({
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
});

export const editRegValidationSchema = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	password: Yup.string().test(
		'empty-check',
		'Password must be at least 8 characters',
		(password) => password.length === 0 || password.length >= 8
	),
	confirmPassword: Yup.string().when('password', {
		is: (password) => password.length > 0,
		then: Yup.string()
			.oneOf([Yup.ref('password'), null], 'Passwords must match')
			.required('Confirm Password is required'),
	}),
});

export const resetPasswordValidation = Yup.object().shape({
	token: Yup.string().required('token is required'),
	password: Yup.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.matches(/\d/, 'Password must contain atleast one number')
		.matches(/[a-zA-Z]/, 'Password must contain atleast one letter'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});

export const chalan_validation = Yup.object().shape({
	challan_from_id: Yup.string().required('Chilling center is required'),
	challan_to_id: Yup.string().required('Industry is required'),
	milk_quantity: Yup.number('Milk Quantity must be a number')
		.typeError('Must be a valid number.')
		// .positive('Milk Quantity must be positive')
		.required('Milk Quantity  is required'),
	fat_percentage: Yup.number('Fat must be a number')
		.typeError('Must be a valid number.')
		// .positive('Fat must be positive')
		.required('Fat is required'),
	snf_percentage: Yup.number('SNF must be a number')
		.typeError('Must be a valid number.')
		// .positive('SNF must be positive')
		.required('SNF is required'),
	// challan_number: Yup.string().required('Challan Number is required'),
	// lacto_percentage: Yup.number('Lacto must be a number')
	// 	.typeError('Must be a valid number.')
	// 	.positive('Lacto must be positive')
	// ,
	vehicle_number: Yup.string(),
	issued_by: Yup.string(),
	// purchase_date: Yup.string().required('Purchase Date is required'),
});

export const schedule_validation = Yup.object().shape({
	chilling_center: Yup.string().required('Chilling center is required'),
	factory: Yup.string().required('Industry is required'),
});

export const chilling_sangh_and_cooperative_validation = Yup.object().shape({
	company_name: Yup.string().required('Name is required'),
	phone_number: Yup.string().required('Phone Number is required'),
	pan: Yup.string().required('Pan is required'),
	address: Yup.string().required('Address is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
});

export const member_validation = Yup.object().shape({
	name: Yup.string().required('Name is required'),
	phone: Yup.string().required('Phone Number is required'),
	address: Yup.string().required('Address is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	code: Yup.string().required('Address is required'),
});

export const employee_validation = Yup.object().shape({
	first_name: Yup.string().required('First Name is required'),
	last_name: Yup.string().required('First Name is required'),
	phone_number: Yup.string().required('Phone Number is required'),
	address: Yup.string().required('Address is required'),
	emp_id: Yup.string().required('Employee Id is required'),
	emp_designation: Yup.string().required('Designation is required'),
});

export const fact0ry_validation = Yup.object().shape({
	company_name: Yup.string().required('Name is required'),
	phone_number: Yup.string().required('Phone Number is required'),
	pan: Yup.string().required('Pan is required'),
	address: Yup.string().required('Address is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
	color_code: Yup.string().required('Color is required'),
});

export const daily_sales_validation = Yup.object().shape({
	// title: Yup.string(),
	// address: Yup.string(),
	milk_quantity: Yup.number('Must be a number')
		.typeError('Must be a valid number.')
		.required('Milk Quantity is required')
		.positive('Milk Quantity must be positive'),
	// paid_amount: Yup.number('Must be a number')
	// 	.typeError('Must be a valid number.')
	// 	.required('Amount Recieved is required')
	// 	.positive('Milk Quantity must be positive'),
});

export const voucher_validation = Yup.object({
	voucher_type: Yup.string()
		.oneOf(['journal', 'purchase', 'payment', 'receipt', 'salesFactory', 'salesChilling'])
		.required('Voucher Type is required'),
	narration: Yup.string().required('Voucher Type is required'),
	nepali_due_date: Yup.string().required('Nepali due date is required'),
	nepali_entry_date: Yup.string().required('Nepali entry date is required'),
	credit_debit: Yup.array().of(
		Yup.object().shape(
			{
				credit_amount: Yup.string().when('debit_amount', {
					is: (debit_amount) => !debit_amount || debit_amount.length === 0,
					then: Yup.string().required('either debit or credit is required'),
					otherwise: Yup.string(),
				}),
				debit_amount: Yup.string().when('credit_amount', {
					is: (credit_amount) => !credit_amount || credit_amount.length === 0,
					then: Yup.string().required('either debit or credit is required'),
					otherwise: Yup.string(),
				}),
				description: Yup.string(),
				particulars: Yup.string().required(),
			},
			[['credit_amount', 'debit_amount']]
		)
	),
});

export const dairy_bill_validation = Yup.object({
	receiver: Yup.string().required('Name is required'),
	receiver_address: Yup.string(),
	receiver_pan: Yup.string(),
	items: Yup.array().of(
		Yup.object().shape({
			item: Yup.string().required('Item is required'),
			quantity: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Qunatity is required')
				.positive('Quantity must be positive')
				.integer('Must be integer')
				.test('max', 'Quntity should be less than or equal to available stock', function (value) {
					if (!this.parent.stock) return true;
					return value <= parseInt(this.parent.stock);
				}),
		})
	),
});

export const bill_validation = Yup.object({
	transaction_date: Yup.string().required('Transaction Date is required'),
	issued_date: Yup.string().required('Bill Date is required'),
	receiver_name: Yup.string().required('Name is required'),
	receiver_address: Yup.string().required('Address  is required'),
	receiver_pan: Yup.string(),
	discount_percentage: Yup.number('Must be a number')
		.typeError('Must be a valid number.')
		.positive('Discount Percentage must be positive')
		.min(0, 'Discount must be positive')
		.max(100, 'Discount must be less than 100'),

	product_details: Yup.array().of(
		Yup.object().shape({
			item_name: Yup.string().required('Name is required'),
			quantity: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Qunatity is required')
				.positive('Quantity must be positive')
				.integer('Must be integer')
				.test('max', 'Quntity should be less than or equal to available stock', function (value) {
					if (!this.parent.product_type) return true;
					return value <= parseInt(this.parent.product_quantity);
				}),

			rate: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Rate is required')
				.positive('Rate must be positive'),
		})
	),
});

export const purchase_bill_validation = Yup.object({
	purchase_bill_number: Yup.string().required('Bill Number is required'),
});

export const ledgerGroupValidation = Yup.object({
	name: Yup.string().required('Name is required'),
	code_np: Yup.string().required('Code is required'),
	main_group: Yup.string().required('Main group is required'),
	remarks: Yup.string().required('Remarks is required'),
});
export const ledgerSubGroupValidation = Yup.object({
	name: Yup.string().required('Name is required'),
	code_np: Yup.string().required('Code is required'),
	parent_id: Yup.string().required('Parent Id is required'),
	description: Yup.string(),
});

export const generalLedgerValidation = Yup.object({
	name: Yup.string().required('Name is required'),
	code_np: Yup.string().required('Code is required'),
	ledger_group: Yup.string().when('has_parent', {
		is: false,
		then: Yup.string(),
		otherwise: Yup.string().required('Ledger group is required'),
	}),
	main_group: Yup.string().when('has_parent', {
		is: false,
		then: Yup.string().required('Main group is required'),
		otherwise: Yup.string(),
	}),
	// main_group,
	sub_ledger: Yup.string(),
	opening_balance: Yup.number('Must be a number').default(0).typeError('Must be a valid number.'),

	is_debit: Yup.string().oneOf(['True', 'False']).required('Ledger group is required'),
	remarks: Yup.string().required(),
});

export const rawMaterialValidation = Yup.object({
	items: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required('Name is required'),
			quantity: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Qunatity is required')
				.positive('Quantity must be positive')
				.integer('Must be integer'),
			unit: Yup.string().required('Unit is required'),
			member: Yup.string().required('Member is required'),
			price: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Price is required')
				.positive('Price must be positive'),
		})
	),
});

export const dairyProductsValidation = Yup.object({
	milk: Yup.string().test('max', 'Quntity should be less than or equal to available stock', function (value) {
		if (!this.parent.milk_stock) return true;
		return value <= parseInt(this.parent.milk_stock);
	}),
	// raw_products: Yup.array().of(
	// 	Yup.object().shape({
	// 		type: Yup.string(),
	// 		quantity: Yup.number('Must be a number')
	// 			.typeError('Must be a valid number.')
	// 			.required('Qunatity is required')
	// 			.positive('Quantity must be positive')
	// 			.integer('Must be integer')
	// 			.test('max', 'Quntity should be less than or equal to available stock', function (value) {
	// 				if (!this.parent.stock) return true;
	// 				return value <= parseInt(this.parent.stock);
	// 			}),
	// 	})
	// ),
	items: Yup.array().of(
		Yup.object().shape({
			entry_date: Yup.string(),
			item_name: Yup.string().required('Name is required'),
			quantity: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Qunatity is required')
				.positive('Quantity must be positive')
				.integer('Must be integer'),
			unit: Yup.string().required('Unit is required'),
			selling_price: Yup.string().required('Selling Price is required'),
			batch_number: Yup.string().required('Batch Number is required'),
		})
	),
});

export const dairyProductsEditValidation = Yup.object({
	items: Yup.array().of(
		Yup.object().shape({
			entry_date: Yup.string(),
			item_name: Yup.string().required('Name is required'),
			quantity: Yup.number('Must be a number')
				.typeError('Must be a valid number.')
				.required('Qunatity is required')
				.positive('Quantity must be positive')
				.integer('Must be integer'),
			unit: Yup.string().required('Unit is required'),
			selling_price: Yup.string().required('Selling Price is required'),
			batch_number: Yup.string().required('Batch Number is required'),
		})
	),
});
