import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	maingroup: [],
	totalResults: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ALL_MAIN_GROUP:
			return { ...state, maingroup: action.payload.results, totalResults: action.payload.count };

		default:
			return state;
	}
}
