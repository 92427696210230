import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import Nepali from '../languages/np.json';
import English from '../languages/en-US.json';

const LanguageWrapper = ({ local, children }) => {
	const messages = local === 'np' ? English : Nepali;
	return (
		<IntlProvider locale={local} messages={messages}>
			{children}
		</IntlProvider>
	);
};

const mapStateToProps = (state) => ({
	local: state.language.locale,
});

export default connect(mapStateToProps)(LanguageWrapper);
