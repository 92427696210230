import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	employee: [],
	empType: [],
	designation: [],
	profile: {},
};

export default function (state = initialState, action) {
	let index, tempEmp;
	switch (action.type) {
		case ActionTypes.GET_ALL_EMPLOYEE:
			return { ...state, employee: action.payload };
		case ActionTypes.GET_ALL_DESIGNATION:
			return { ...state, designation: action.payload };
		case ActionTypes.GET_ALL_EMP_TYPE:
			return { ...state, empType: action.payload };
		case ActionTypes.GET_EMPLOYEE_PROFILE:
			return { ...state, profile: action.payload };
		case ActionTypes.REMOVE_EMPLOYEE:
			tempEmp = [...state.employee];
			index = tempEmp.findIndex((ind) => ind.url === action.payload);
			tempEmp.splice(index, 1);
			return { ...state, employee: tempEmp };

		case ActionTypes.SET_NEW_EMPLOYEE:
			tempEmp = [...state.employee];
			index = tempEmp.findIndex((ind) => ind.id === action.payload.id);
			if (index !== -1) {
				tempEmp[index] = action.payload;
			} else {
				tempEmp.push(action.payload);
			}
			return { ...state, employee: tempEmp };
		// case ActionTypes.REMOVE_INDUSTRY:
		// 	tempIndustry = [...state.industries];
		// 	index = tempIndustry.findIndex((ind) => ind.url === action.payload);
		// 	tempIndustry.splice(index, 1);
		// 	return { ...state, industries: tempIndustry };

		case ActionTypes.LOADING_EMPLOYEE:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_EMPLOYEE:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
