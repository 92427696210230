import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	schedules: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_ALL_SCHEDULES:
			return { ...state, schedules: action.payload };
		case ActionTypes.SET_NEW_SCHEDULE:
			return { ...state, schedules: [...action.payload, ...state.schedules] };
		case ActionTypes.REMOVE_SCHEDULE:
			const tempSchedule = [...state.schedules];
			const index = tempSchedule.findIndex((sch) => sch.id === action.payload);
			tempSchedule.splice(index, 1);
			return { ...state, schedules: tempSchedule };
		case ActionTypes.LOADING_SCHEDULE:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_SCHEDULE:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
