export const bsMonths = [
	'बैशाख',
	'जेठ',
	'आषाढ',
	'श्रावण',
	'भदौ',
	'असोज',
	'कार्तिक',
	'मंसिर',
	'पौष',
	'माघ',
	'फाल्गुन',
	'चैत्र',
];
export const bsMonthsEnglish = [
	'Baisakh',
	'Jesth',
	'Aasad',
	'Srawan',
	'Bhadra',
	'Aaswin',
	'Kartik',
	'Mangsir',
	'Paush',
	'Magh',
	'Falgun',
	'Chaitra',
];
export const bsDays = ['आईत', 'सोम', 'मंगल', 'बुध', 'बिही', 'शुक्र', 'शनि'];
export const bsDaysSmall = ['आ.', 'सो.', 'मं.', 'बु.', 'बि.', 'शु.', 'श.'];
export const bsDaysFull = ['आइतवार', 'सोमवार', 'मंगलवार', 'बुधवार', 'बिहिवार', 'शुक्रवार', 'शनिवार'];
export const adDaysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const adDays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
export const nepaliNumbers = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
export const adMonths = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];
