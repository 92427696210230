import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const  getAllChillingCenters = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_CHILLING_CENTER });
		const response = await fetchWrapper.get(COMMON_URL + 'chilling-center/');
		dispatch({ type: ActionTypes.SET_ALL_CHILLING_CENTERS, payload: response });
		dispatch({ type: ActionTypes.STOP_LOADING_CHILLING_CENTER });
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_CHILLING_CENTER });
	}
};

export const postAChillingCenter = (newChillingCenter, history) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.post(COMMON_URL + 'chilling-center/', newChillingCenter);
		dispatch({ type: ActionTypes.SET_NEW_CHILLING_CENTER, payload: response });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Chilling center created successfully ' },
		});

		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		history.push('/chillingcenter');
	} catch (e) {
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};

export const editChillingCenter = (id, newChillingCenter, history) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.put(COMMON_URL + `chilling-center/${id}/`, newChillingCenter);
		dispatch({ type: ActionTypes.SET_NEW_CHILLING_CENTER, payload: response });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Chilling center edited successfully ' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		history.push('/chillingcenter');
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
	}
};

export const deleteChillingCenter = (url) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.delete(url);
		dispatch({ type: ActionTypes.REMOVE_CHILLING_CENTER, payload: url });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Chilling center deleted successfully ' },
		});
		if (response) dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};

export const getAllCooperative = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_COOPERATIVE });
		const response = await fetchWrapper.get(COMMON_URL + 'cooperative/');
		dispatch({ type: ActionTypes.SET_ALL_COOPERATIVES, payload: response });

		dispatch({ type: ActionTypes.STOP_LOADING_COOPERATIVE });
	} catch (e) {
		console.log(e);
		dispatch({ type: ActionTypes.STOP_LOADING_COOPERATIVE });
	}
};

export const postACooperative = (newCooperative) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.post(COMMON_URL + 'cooperative/', newCooperative);
		dispatch({ type: ActionTypes.SET_NEW_COOPERATIVE, payload: response });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Cooperative Added successfully ' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		return true;
	} catch (e) {
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		return false;
	}
};

export const editCooperative = (coopId, newCooperative) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.put(COMMON_URL + `cooperative/${coopId}/`, newCooperative);
		dispatch({ type: ActionTypes.SET_NEW_COOPERATIVE, payload: response });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Cooperative edited successfully ' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
	}
};

export const deleteCooperative = (url) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.delete(url);
		dispatch({ type: ActionTypes.REMOVE_COOPERATIVE, payload: url });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Cooperative deleted successfully ' },
		});

		if (response) dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};

export const getAllIndustry = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_INDUSTRY });
		const response = await fetchWrapper.get(COMMON_URL + 'factory/');
		dispatch({ type: ActionTypes.SET_ALL_INDUSTRIES, payload: response });

		dispatch({ type: ActionTypes.STOP_LOADING_INDUSTRY });
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_INDUSTRY });
	}
};
export const postAIndustry = (newCooperative, history) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.post(COMMON_URL + 'factory/', newCooperative);
		dispatch({ type: ActionTypes.SET_NEW_INDUSTRY, payload: response });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Industry created successfully ' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		history.push('/industry');
	} catch (e) {
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};

export const editIndustry = (indId, newIndustry, history) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.put(COMMON_URL + `factory/${indId}/`, newIndustry);
		dispatch({ type: ActionTypes.SET_NEW_INDUSTRY, payload: response });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'industry edited successfully ' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		history.push('/industry');
	} catch (e) {
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
	}
};

export const deleteIndustry = (url) => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.LOADING_UI });
		const response = await fetchWrapper.delete(url);
		dispatch({ type: ActionTypes.REMOVE_INDUSTRY, payload: url });
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: false, message: 'Industry deleted successfully ' },
		});
		if (response) dispatch({ type: ActionTypes.STOP_LOADING_UI });
	} catch (e) {
		dispatch({
			type: ActionTypes.SET_MESSAGE,
			payload: { error: true, message: 'Something went wrong' },
		});
		dispatch({ type: ActionTypes.STOP_LOADING_UI });
	}
};
