import { fetchWrapper } from '../../_helpers';
import * as ActionTypes from '../ActionTypes';
import { logoutUser } from './ActionAuthentication';
const COMMON_URL = process.env.REACT_APP_BACKEND_URL;

export const getUserData = () => async (dispatch) => {
	try {
		const response = await fetchWrapper.get(COMMON_URL + `auth/users/me`);
		if (response) {
			let roleText = '';
			if (response.is_superuser) {
				roleText = 'superadmin';
			} else {
				// const { has_daily_sales_permissions, has_repair_permissions, has_stationery_permissions } = response;
				// if (has_daily_sales_permissions) roleText = 'ds_';
				// if (has_repair_permissions) roleText = roleText + 'rs_';
				// if (has_stationery_permissions) roleText = roleText + 'ss_';
				roleText = roleText + 'user';
			}
			response.role = roleText;
		}
		try {
			const fiscal = await fetchWrapper.get(COMMON_URL + `fiscal-year/active`);
			response.fiscal = fiscal.data;
		} catch (e) {
			console.log(e);
		}
		dispatch({ type: ActionTypes.SET_USER, payload: response });
	} catch (e) {
		if (e.code === 401 || e.code === 403) {
			dispatch(logoutUser());
		}
	}
};

export const getAllUser =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: ActionTypes.SET_USER_LOADING });
			const response = await fetchWrapper.get(COMMON_URL + `auth/users/?page=${page}`);
			dispatch({ type: ActionTypes.SET_ALL_USER, payload: response });
			dispatch({ type: ActionTypes.CLEAR_USER_LOADING });
		} catch (e) {
			dispatch({ type: ActionTypes.CLEAR_USER_LOADING });

			console.log(e);
		}
	};

export const deleteAUser = (userId) => async (dispatch) => {
	try {
		await fetchWrapper.delete(COMMON_URL + `users/${userId}`);
		dispatch({ type: ActionTypes.REMOVE_DELETED_USER, payload: userId });
	} catch (e) {
		console.log(e);
	}
};

export const getSanghData = () => async (dispatch) => {
	try {
		dispatch({ type: ActionTypes.SET_USER_LOADING });
		const res = await fetchWrapper.get(process.env.REACT_APP_BACKEND_URL + `sangh/`);
		dispatch({ type: ActionTypes.SET_SANGH, payload: res[0] });
		dispatch({ type: ActionTypes.CLEAR_USER_LOADING });
	} catch (e) {}
};
