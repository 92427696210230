import { toast } from 'react-toastify';

export const roundReal = (value, decimals) => {
	return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

// export const roundAlways = (value, decimals) => {
// 	return Number(value).toFixed(decimals);
// };

export const truncate = (num, fixed = 2) => {
	if (num) {
		var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
		const number = num.toString().match(re) ? num.toString().match(re)[0] : 0;
		return Number(number);
	} else {
		return 0;
	}
};

export const round = truncate;
export const roundAlways = truncate;

export const groupBy = (slots, key) => {
	const merged = slots.reduce((r, { factory_name, ...rest }) => {
		if (r[factory_name]) {
			for (const field in rest) {
				if (typeof r[factory_name][field] === 'number') {
					r[factory_name][field] += rest[field];
				} else if (field !== factory_name) {
					delete r[factory_name][field];
				}
			}
		} else {
			r[factory_name] = {
				factory_name,
				...rest,
				groupedData: [],
			};
		}
		r[factory_name]['groupedData'].push(rest);
		return r;
	}, {});

	return Object.values(merged);
};

export const groupSchedule = (schedule, daysNumber) => {
	let sch = {};
	let totalFirstHalf = 0;
	let totalSecondHalf = 0;
	for (let i = 1; i <= daysNumber; i++) {
		sch[i] = [];
	}

	if (schedule.length) {
		for (let index = 0; index < schedule.length; index++) {
			const date = parseInt(schedule[index].schedule_date_nepali.split('-')[2]);

			if (date <= 15) {
				totalFirstHalf += 1;
			} else {
				totalSecondHalf += 1;
			}
			if (sch[date]) sch[date].push({ ...schedule[index] });
		}
		return { ...sch, totalFirstHalf, totalSecondHalf };
	} else {
		return { ...sch, totalFirstHalf, totalSecondHalf };
	}
};

export const isEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const groupBalanceSheet = (array) => {
	const data = {};
	const anusuchiMap = {};
	array.forEach((element) => {
		if (element.parent_ledger) {
			if (!data[element.parent_ledger]) data[element.parent_ledger] = {};
			if (element.sub_ledger) {
				if (!data[element.parent_ledger][element.sub_ledger])
					data[element.parent_ledger][element.sub_ledger] = {};
				if (element.general_ledger) {
					if (!anusuchiMap['general_ledger']) anusuchiMap['general_ledger'] = {};
					anusuchiMap['general_ledger'][element.general_ledger] = element.anusuchi;
					data[element.parent_ledger][element.sub_ledger][element.general_ledger] = truncate(element.amount);
				} else {
					if (!anusuchiMap['sub_ledger']) anusuchiMap['sub_ledger'] = {};
					anusuchiMap['sub_ledger'][element.sub_ledger] = element.anusuchi;
					data[element.parent_ledger][element.sub_ledger] = truncate(element.amount);
				}
			} else {
				if (!anusuchiMap['parent_ledger']) anusuchiMap['parent_ledger'] = {};
				anusuchiMap['parent_ledger'][element.parent_ledger] = element.anusuchi;
				data[element.parent_ledger] = truncate(element.amount);
			}
		}
	});
	return { data, anusuchiMap };
};

export const commaSeparatedNumber = (value) => {
	const num = Number(value).toFixed(2);
	return Intl.NumberFormat('en-IN').format(Number(num));
};

export const ErrorHandler = (e) => {
	if (Array.isArray(e.detail)) {
		toast.error(e.detail[0]);
	} else if (e.detail) {
		toast.error(e.detail);
	} else {
		toast.error('Something went wrong');
	}
};

export default function debounce(fn, delay = 250) {
	let timeout;

	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			fn(...args);
		}, delay);
	};
}
