import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	cooperatives: [],
};

export default function (state = initialState, action) {
	let tempCooperative, index;
	switch (action.type) {
		case ActionTypes.SET_ALL_COOPERATIVES:
			return { ...state, cooperatives: action.payload };
		case ActionTypes.SET_NEW_COOPERATIVE:
			tempCooperative = [...state.cooperatives];
			index = tempCooperative.findIndex((coop) => coop.id === action.payload.id);
			if (index !== -1) {
				tempCooperative[index] = action.payload;
			} else {
				tempCooperative.unshift(action.payload);
			}
			return { ...state, cooperatives: tempCooperative };
		case ActionTypes.REMOVE_COOPERATIVE:
			tempCooperative = [...state.cooperatives];
			index = tempCooperative.findIndex((coop) => coop.url === action.payload);
			tempCooperative.splice(index, 1);
			return { ...state, cooperatives: tempCooperative };

		case ActionTypes.LOADING_COOPERATIVE:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_COOPERATIVE:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
