const dates = {
  1970: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  1971: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  1972: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  1973: [
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 31
    }
  ],
  1974: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  1975: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  1976: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 31
    }
  ],
  1977: [
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 31
    }
  ],
  1978: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  1979: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  1980: [
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 31
    }
  ],
  1981: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  1982: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  1983: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  1984: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 31
    }
  ],
  1985: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  1986: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  1987: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  1988: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 31
    }
  ],
  1989: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  1990: [
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  1991: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  1992: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  1993: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  1994: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  1995: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  1996: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  1997: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  1998: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  1999: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2000: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 31
    }
  ],
  2001: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2002: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2003: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 31
    }
  ],
  2004: [
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 31
    }
  ],
  2005: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2006: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2007: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 31
    }
  ],
  2008: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 31
    }
  ],
  2009: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2010: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2011: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 31
    }
  ],
  2012: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2013: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2014: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2015: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 31
    }
  ],
  2016: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2017: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2018: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2019: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 31
    }
  ],
  2020: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2021: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2022: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2023: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 31
    }
  ],
  2024: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2025: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2026: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 31
    }
  ],
  2027: [
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 31
    }
  ],
  2028: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2029: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2030: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 31
    }
  ],
  2031: [
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 31
    }
  ],
  2032: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2033: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2034: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 31
    }
  ],
  2035: [
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 31
    }
  ],
  2036: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2037: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2038: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 31
    }
  ],
  2039: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2040: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2041: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2042: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 31
    }
  ],
  2043: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2044: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2045: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2046: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 31
    }
  ],
  2047: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2048: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2049: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2050: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 31
    }
  ],
  2051: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2052: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2053: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2054: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 31
    }
  ],
  2055: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2056: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2057: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 31
    }
  ],
  2058: [
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 31
    }
  ],
  2059: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2060: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2061: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 31
    }
  ],
  2062: [
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 31
    }
  ],
  2063: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2064: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2065: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 31
    }
  ],
  2066: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 31
    }
  ],
  2067: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2068: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2069: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 31
    }
  ],
  2070: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2071: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2072: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2073: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 31
    }
  ],
  2074: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2075: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2076: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2077: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 31
    }
  ],
  2078: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2079: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2080: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2081: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2082: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2083: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2084: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2085: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 29
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    }
  ],
  2086: [
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2087: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2088: [
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2089: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    }
  ],
  2090: [
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2091: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 32
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2092: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    }
  ],
  2093: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2094: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ],
  2095: [
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 32
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 29
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2096: [
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 32
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 29
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    }
  ],
  2097: [
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 32
    },
    {
      weekStart: 5,
      endDate: 31
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 29
    },
    {
      weekStart: 1,
      endDate: 30
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    }
  ],
  2098: [
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 32
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 31
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 31
    }
  ],
  2099: [
    {
      weekStart: 1,
      endDate: 31
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 32
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 31
    },
    {
      weekStart: 3,
      endDate: 31
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 29
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 30
    },
    {
      weekStart: 0,
      endDate: 30
    }
  ],
  2100: [
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 31
    },
    {
      weekStart: 5,
      endDate: 32
    },
    {
      weekStart: 2,
      endDate: 30
    },
    {
      weekStart: 4,
      endDate: 31
    },
    {
      weekStart: 0,
      endDate: 30
    },
    {
      weekStart: 2,
      endDate: 29
    },
    {
      weekStart: 3,
      endDate: 30
    },
    {
      weekStart: 5,
      endDate: 29
    },
    {
      weekStart: 6,
      endDate: 30
    },
    {
      weekStart: 1,
      endDate: 30
    }
  ]
}

export const currentYear = (year) => {
  return dates[year]
}

export const getYears = (year) => {
  return Object.keys(dates)
}
 export const getMonth=(year,month)=>{
   return dates[year][month-1]
 }