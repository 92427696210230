import {
	NP_MINUS,
	NP_UPTO_HUNDREDS,
	NP_TENS,
	// NP_POINT,
	MAX_SUPPORTED_NUMBER,
	NP_RUPEES,
	NP_PAISA,
	WORD_FORMAT,
} from './datas';

/**
 * Array for non-fractional part of number
 */
let nonFractionalWords = [];

/**
 * Class that converts Nepali number to words
 */
export const wordConverter = (num, format) => {
	nonFractionalWords = [];

	/**
	 * Check for number validity
	 */
	if (!num || isNaN(+num)) {
		console.log(`${num} is not a valid number`);
		return;
	}

	//To number
	if (num > MAX_SUPPORTED_NUMBER) {
		console.log(`${num} is not suppoted by library. Maximum supported number is:${MAX_SUPPORTED_NUMBER}`);
		return;
	}

	const words = toWords(num, format);
	return words.join(' ');
};

/**
 * Convert decimal part of provided number into words
 * @param num
 */
const convertDecimalWords = (num, format) => {
	/**
	 * Array for fractional part of number
	 */
	// let fractionalWords = [];

	// fractionalWords = num
	// 	.toString()
	// 	.split('')
	// 	.map((n) => {
	// 		if (n === '.') {
	// 			return format !== WORD_FORMAT.MONEY ? NP_POINT : '';
	// 		}
	// 		return NP_UPTO_HUNDREDS[n];
	// 	});
	//Add Paisa add end of decimal part if format is `money`
	// if (format === WORD_FORMAT.MONEY) fractionalWords.push(NP_PAISA);
	// return fractionalWords;
	const tempNum = num.replace('.', '');
	let newNumber = Math.abs(+tempNum);

	let nonFractoinalFractionWords = [];
	convertToWords(newNumber);

	if (format === WORD_FORMAT.MONEY) nonFractoinalFractionWords.push(NP_PAISA);
	return nonFractoinalFractionWords;
};

/**
 * Function that converts provided number into corresponding words in nepali
 * @param num
 */
const toWords = (num, format) => {
	let words = [];
	let fractionalWords = [];

	let newNumber = num;

	/**
	 * Check for negative
	 */
	if (num < 0) {
		words = [NP_MINUS];
	}

	newNumber = Math.abs(+newNumber);
	/**
	 * Check for decimal part
	 */

	//Conversion for non-fractional part
	const value = Math.floor(newNumber);
	convertToWords(value, format);

	const decimalMatch = newNumber.toString().match(/\.\d+/);
	if (decimalMatch) {
		//Decimal part with .
		const decimalPart = decimalMatch[0];
		//Convert decimal/fractional part to word
		fractionalWords = convertDecimalWords(decimalPart, format);
	}

	//Merge operator, non-fractional & fractional part for final words
	words = [...words, ...nonFractionalWords, ...fractionalWords];
	return words;
};

/**
 * Function that converts non-fractional part of number into words
 * @param num
 */
const convertToWords = (number, format) => {
	//Find length of current number
	let num = number;
	num = +num;
	const numLength = num.toString().length;
	//If length is less then or equal to 2 then return mapped nepalstring from hundred mapping
	if (numLength <= 2) {
		nonFractionalWords = [...nonFractionalWords, NP_UPTO_HUNDREDS[num]];

		//Add Rupees add end of decimal part if format is `money`
		if (format === WORD_FORMAT.MONEY) nonFractionalWords.push(NP_RUPEES);
	} else {
		//Evaluate tenth dividend if length is 3(number belongs to 100 to 999) dividend is 10**2 i.e 100
		//Eg. num is 1123 then tenthDividend is 10**2 i.e 1000
		let tenthDividend = 10 ** (numLength - 1);
		//Search dividend in NP_TENS Object. for both thousand and ten thousand dividend is 10**3
		while (!NP_TENS[tenthDividend]) {
			tenthDividend /= 10;
		}
		//Calculate quotient
		//quotient = 1123/1000 = 1
		const quotient = Math.floor(num / tenthDividend);
		//Calculate remainder
		//remainder = 1123%1000 = 23
		const remainder = Math.floor(num % tenthDividend);
		//Prepare word
		//NP_UPTO_HUNDREDS[1] i.e एक, NP_TENS[1000] i.e हजार so word is एक हजार
		const word = `${NP_UPTO_HUNDREDS[quotient]} ${NP_TENS[tenthDividend]}`;
		nonFractionalWords = [...nonFractionalWords, word];
		//Perform same operation for remainder if it is not zero which is 23
		if (remainder !== 0) convertToWords(remainder, format);
	}
};
