import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import { ConfigureStore } from './_redux/configureStore';
import LanguageWrapper from './_helpers/LanguageWrapper';

React.icons = icons;

ReactDOM.render(
	<Provider store={ConfigureStore()}>
		<LanguageWrapper>
			<App />
		</LanguageWrapper>
	</Provider>,
	document.getElementById('root')
);
