import { createStore, combineReducers, applyMiddleware } from 'redux';

import User from './reducers/user';
import UI from './reducers/ui';
import ChillingCenters from './reducers/chillngCenters';
import Cooperatives from './reducers/cooperatives';
import Industries from './reducers/industry';
import Chalans from './reducers/chalan';
import Schdeule from './reducers/schedule';
import AdminPanal from './reducers/adminpanal';
import Setting from './reducers/setting';
import MainGroup from './reducers/maingroup';
import LedgerGroup from './reducers/ledgergroup';
import Subgroup from './reducers/subgroup';
import Ledger from './reducers/ledger';
import Voucher from './reducers/voucher';
import ClosingStock from './reducers/closingStock';

import thunk from 'redux-thunk';
import logger from 'redux-logger';
import Payment from './reducers/payment';
import Language from './reducers/languages';
import RAS from './reducers/stationaryAndRepair';
import report from './reducers/report';
import employee from './reducers/employee';

export const ConfigureStore = () => {
	const store = createStore(
		combineReducers({
			user: User,
			ui: UI,
			chillingCenters: ChillingCenters,
			cooperatives: Cooperatives,
			industries: Industries,
			chalans: Chalans,
			schedules: Schdeule,
			adminpanal: AdminPanal,
			setting: Setting,
			payment: Payment,
			language: Language,
			rAS: RAS,
			maingroup: MainGroup,
			ledgergroup: LedgerGroup,
			subgroup: Subgroup,
			ledger: Ledger,
			voucher: Voucher,
			closingStock: ClosingStock,
			report: report,
			employee: employee,
		}),

		applyMiddleware(thunk, logger)
	);
	return store;
};
