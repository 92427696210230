import React, { useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './scss/style.scss';

//redux
import { connect } from 'react-redux';
import { getAllChillingCenters, getAllCooperative, getAllIndustry } from './_redux/actions/ActionOrganizations';
import { getUserData, getSanghData } from './_redux/actions/ActionUser';
import { AuthRoute, PrivateRoute } from './_helpers';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./components/Auth/Login'));
const ApiChange = React.lazy(() => import('./components/Auth/ApiChange'));

const App = ({
	getAllChillingCenters,
	getAllCooperative,
	getAllIndustry,
	message,
	isLogged,
	getUserData,
	getSanghData,
}) => {
	//data initialization
	useEffect(() => {
		if (isLogged) {
			getUserData();
			getSanghData();
			getAllChillingCenters();
			getAllCooperative();
			getAllIndustry();
		}
	}, [getAllChillingCenters, getAllCooperative, getAllIndustry, isLogged, getUserData, getSanghData]);

	// centralized message display
	useEffect(() => {
		if (message) {
			if (message.error) {
				toast.error(message.message);
			} else {
				toast.success(message.message);
			}
		}
	}, [message]);

	return (
		<HashRouter>
			<React.Suspense fallback={loading}>
				<Switch>
					<Route exact path="/api" name="Api Change Page" component={ApiChange} />
					<AuthRoute exact path="/login" name="Login Page" component={Login} />
					<PrivateRoute path="/" name="Home" component={TheLayout} />
				</Switch>
			</React.Suspense>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</HashRouter>
	);
};

App.propTypes = {};

const mapStateToProps = (state) => ({
	message: state.ui.message,
	isLogged: state.user.isLogged,
});

export default connect(mapStateToProps, {
	getAllChillingCenters,
	getAllCooperative,
	getAllIndustry,
	getUserData,
	getSanghData,
})(App);
