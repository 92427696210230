import { nepaliNumbers } from './variables';

export const toNepaliNumber = function (number) {
	const strNum = String(number);
	let strNepNum = '';
	for (let i = 0; i < strNum.length; i++) {
		strNepNum += nepaliNumbers[parseInt(strNum.substr(i, 1))] || strNum.substr(i, 1);
	}
	return strNepNum;
};

export const nepaliNumber = (number) => {
	const strNum = String(number);
	let strNepNum = '';
	for (let i = 0; i < strNum.length; i++) {
		strNepNum +=
			nepaliNumbers[parseInt(strNum.substr(i, 1))] ||
			(nepaliNumbers.includes(strNum.substr(i, 1)) && strNum.substr(i, 1)) ||
			'';
	}
	return strNepNum;
};

const n = '०१२३४५६७८९';
const nN = n.split('');

export const getEnglishNumber = (n, d = true, c = '') => {
	let sN = String(n);
	let sNN = '';
	if (!d) sN = sN.replace(/[^०१२३४५६७८९.]/g, '');
	for (let i = 0; i < sN.length; i++) {
		if (nN.indexOf(sN[i]) > -1) sNN += nN.indexOf(sN[i]);
		else sNN += sN[i];
	}
	if (!d) sNN = getComma(sNN, c);
	return sNN;
};

export const getComma = (sNN, c) => {
	if (c) {
		if (c === 'np') {
			sNN = new Intl.NumberFormat('en-IN').format(sNN);
		} else if (c === 'en') {
			sNN = new Intl.NumberFormat('en-US').format(sNN);
		}
	}
	return sNN;
};
