import * as ActionTypes from '../ActionTypes';
import { jwtCheck } from '../../_helpers';
import { useToday, useFifteenDays, useThirtyDays, useSevenDays } from '../../components/Schedule/Calender';
const today = useToday();
const fifteen_days = useFifteenDays();
const seven_days = useSevenDays();
const thirty_days = useThirtyDays();

const initialState = {
	isLogged: jwtCheck() ? true : false,
	isLoading: false,
	today: `${today.year}-${('0' + today.nmonth).slice(-2)}-${('0' + today.date).slice(-2)}`,
	fifteen_days: `${fifteen_days.year}-${('0' + fifteen_days.nmonth).slice(-2)}-${('0' + fifteen_days.date).slice(
		-2
	)}`,
	seven_days: `${seven_days.year}-${('0' + seven_days.nmonth).slice(-2)}-${('0' + seven_days.date).slice(-2)}`,
	thirty_days: `${thirty_days.year}-${('0' + thirty_days.nmonth).slice(-2)}-${('0' + thirty_days.date).slice(-2)}`,

	todayData: today,
	user: null,
	allUser: [],
	totalUsers: 0,
	position: null,
	sanghInfo: null,
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_LOGGEDIN:
			return { ...state, isLogged: true };
		case ActionTypes.SET_LOGGEDOUT:
			return { ...state, isLogged: false, user: null, allUser: [] };
		case ActionTypes.SET_ALL_USER:
			return {
				...state,
				allUser: action.payload.results,
				totalUsers: action.payload.count,
			};
		case ActionTypes.SET_NEW_USER:
			return {
				...state,
				allUser: [action.payload, ...state.allUser],
				totalUsers: state.totalUsers + 1,
			};
		case ActionTypes.REMOVE_DELETED_USER:
			const tempUsers = [...state.allUser];
			const index = tempUsers.findIndex((user) => user.id === action.payload);
			tempUsers.splice(index, 1);
			return { ...state, allUser: tempUsers, totalUsers: state.totalUsers - 1 };
		case ActionTypes.SET_USER_LOADING:
			return { ...state, isLoading: true };
		case ActionTypes.CLEAR_USER_LOADING:
			return { ...state, isLoading: false };
		case ActionTypes.SET_USER:
			return {
				...state,
				isLogged: true,
				user: action.payload,
			};

		case ActionTypes.SET_USER_FISCAL:
			return {
				...state,
				isLogged: true,
				user: { ...state.user, fiscal: action.payload },
			};

		case ActionTypes.SET_TODAYS_DATE:
			return { ...state, today: action.payload };

		case ActionTypes.SET_SANGH:
			return { ...state, sanghInfo: action.payload };

		default:
			return state;
	}
}
