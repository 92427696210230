import { authHeader } from './auth-header';

const replaceUrl = (url) => {
	const localserver = localStorage.getItem('localserver');
	if (localserver) {
		return url.replace(process.env.REACT_APP_BACKEND_URL, localserver);
	}
	// url = url.replace(
	// 	process.env.REACT_APP_BACKEND_URL,
	// 	'https://samsung-native-finland-kidney.trycloudflare.com/api/'
	// );
	return url;
};

function get(url) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
		credentials: 'same-origin',
	};
	url = replaceUrl(url);
	return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	url = replaceUrl(url);

	return fetch(url, requestOptions).then(handleResponse);
}

function postMultipart(url, body) {
	const multipart = true;
	const requestOptions = {
		method: 'POST',
		headers: authHeader(multipart),
		body,
	};
	url = replaceUrl(url);
	return fetch(url, requestOptions).then(handleResponse);
}

function putMultipart(url, body) {
	const multipart = true;
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(multipart),
		body,
	};
	url = replaceUrl(url);

	return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	url = replaceUrl(url);

	return fetch(url, requestOptions).then(handleResponse);
}

function patch(url, body) {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	url = replaceUrl(url);
	return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	url = replaceUrl(url);
	return fetch(url, requestOptions).then(handleResponse);
}

// helper functions
function handleResponse(response) {
	return response.text().then((text) => {
		try {
			const data = text && JSON.parse(text);

			if (!response.ok) {
				if (data.non_field_errors && data.non_field_errors.length) {
					return Promise.reject({ code: response.status, detail: data.non_field_errors[0] });
				}
				if (response.status === 401) {
					const error = { code: response.status, detail: response.statusText };
					return Promise.reject(error);
				}

				const errorText =
					!Array.isArray(data) && typeof data === 'object'
						? Object.values(data).length && Object.values(data)[0]
						: Array.isArray(data)
						? data
						: '';

				const error = {
					code: (data && data.code) || response.status,
					detail: (data && data.detail) || (data && data.message) || errorText || response.statusText,
				};

				return Promise.reject(error);
			}

			return data;
		} catch (e) {
			const error = { code: 500, detail: 'something went wrong' };
			return Promise.reject(error);
		}
	});
}

export const fetchWrapper = {
	get,
	post,
	postMultipart,
	putMultipart,
	put,
	patch,
	delete: _delete,
};
