import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	voucher: [],
	totalResults: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ALL_VOUCHER:
			return { ...state, voucher: action.payload.results, totalResults: action.payload.count };
		case ActionTypes.POST_VOUCHER:
			return { ...state, voucher: [action.payload, ...state.voucher], totalResults: state.totalResults + 1 };
		case ActionTypes.DELETE_VOUCHER:
			const tempVoucher = [...state.voucher];
			const index = tempVoucher.findIndex((group) => group.id === action.payload);
			let totalVoucher = state.totalResults;

			if (index !== -1) {
				tempVoucher.splice(index, 1);
				totalVoucher = totalVoucher - 1;
			}
			return { ...state, voucher: tempVoucher, totalResults: totalVoucher };

		case ActionTypes.LOADING_VOUCHER:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_VOUCHER:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
