import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	chillingCenters: [],
};

export default function (state = initialState, action) {
	let index, tempChillingCenters;
	switch (action.type) {
		case ActionTypes.SET_ALL_CHILLING_CENTERS:
			return { ...state, chillingCenters: action.payload };
		case ActionTypes.SET_NEW_CHILLING_CENTER:
			tempChillingCenters = [...state.chillingCenters];
			index = tempChillingCenters.findIndex((chilling) => chilling.id === action.payload.id);
			if (index !== -1) {
				tempChillingCenters[index] = action.payload;
			} else {
				tempChillingCenters.unshift(action.payload);
			}
			return { ...state, chillingCenters: tempChillingCenters };
		case ActionTypes.REMOVE_CHILLING_CENTER:
			tempChillingCenters = [...state.chillingCenters];
			index = tempChillingCenters.findIndex((chilling) => chilling.url === action.payload);
			tempChillingCenters.splice(index, 1);
			return { ...state, chillingCenters: tempChillingCenters };

		case ActionTypes.LOADING_CHILLING_CENTER:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_CHILLING_CENTER:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
