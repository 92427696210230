import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	subgroup: [],
	totalResults: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ALL_SUB_LEDGER:
			return { ...state, subgroup: action.payload.results, totalResults: action.payload.count };
		case ActionTypes.POST_SUB_LEDGER:
			return { ...state, subgroup: [action.payload, ...state.subgroup], totalResults: state.totalResults + 1 };
		case ActionTypes.DELETE_SUB_LEDGER:
			const tempSubGroup = [...state.subgroup];
			const index = tempSubGroup.findIndex((group) => group.id === action.payload);
			let totalSubGroup = state.totalResults;

			if (index !== -1) {
				totalSubGroup = totalSubGroup - 1;

				tempSubGroup.splice(index, 1);
			}
			return { ...state, subgroup: tempSubGroup, totalResults: totalSubGroup };
		case ActionTypes.LOADING_SUB_LEDGER:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_SUB_LEDGER:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
