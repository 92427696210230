// export function authHeader() {
// 	let token = localStorage.getItem('token');

// 	if (token && token) {
// 		return {
// 			Authorization: `Token ${token}`,
// 			'Content-Type': 'application/json',
// 		};
// 	} else {
// 		return { 'Content-Type': 'application/json' };
// 	}
// }

export function authHeader(multipart) {
	let token = localStorage.getItem('token');

	if (token && token) {
		if (multipart) {
			return {
				Authorization: `Token ${token}`,
			};
		} else {
			return {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			};
		}
	} else {
		return { 'Content-Type': 'application/json' };
	}
}
