import * as ActionTypes from '../ActionTypes';

const initialState = {
	repairItems: [],
	stationaryItems: [],
	repairBilling: [],
	stationaryBilling: [],
	stationaryLoading: false,
	repairLoading: false,
};

export default function (state = initialState, action) {
	let index;
	switch (action.type) {
		case ActionTypes.SET_REPAIR_LIST:
			return {
				...state,
				repairItems: [...action.payload],
			};
		case ActionTypes.SET_STATIONARY_LIST:
			return {
				...state,
				stationaryItems: [...action.payload],
			};
		case ActionTypes.UPDATE_REPAIR:
			const tempRepairItem = [...state.repairItems];
			index = tempRepairItem.findIndex((item) => item.id === action.payload.id);
			if (index !== -1) {
				tempRepairItem[index] = action.payload;
			} else {
				tempRepairItem.push(action.payload);
			}
			return { ...state, repairItems: tempRepairItem };

		case ActionTypes.UPDATE_STATIONARY:
			const tempStationaryItem = [...state.stationaryItems];
			index = tempStationaryItem.findIndex((item) => item.id === action.payload.id);
			if (index !== -1) {
				tempStationaryItem[index] = action.payload;
			} else {
				tempStationaryItem.push(action.payload);
			}
			return { ...state, stationaryItems: tempStationaryItem };
		case ActionTypes.REMOVE_REPAIR:
			const tempRepair = [...state.repairItems];
			index = tempRepair.findIndex((item) => item.id === action.payload);
			tempRepair.splice(index, 1);
			return { ...state, repairItems: tempRepair };

		case ActionTypes.REMOVE_STATIONARY:
			const tempStationary = [...state.stationaryItems];
			index = tempStationary.findIndex((item) => item.id === action.payload);
			tempStationary.splice(index, 1);
			return { ...state, stationaryItems: tempStationary };
		case ActionTypes.LOADING_STATIONARY:
			return {
				...state,
				stationaryLoading: true,
			};
		case ActionTypes.STOP_LOADING_STATIONARY:
			return {
				...state,
				stationaryLoading: false,
			};
		case ActionTypes.LOADING_REPAIR:
			return {
				...state,
				repairLoading: true,
			};
		case ActionTypes.STOP_LOADING_REPAIR:
			return {
				...state,
				repairLoading: false,
			};
		default:
			return state;
	}
}
