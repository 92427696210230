import * as ActionTypes from '../ActionTypes';

const initialState = {
	locale: navigator.language,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_LANGUAGE:
			return { ...state, locale: action.payload };

		default:
			return state;
	}
}
