import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	industries: [],
};

export default function (state = initialState, action) {
	let index, tempIndustry;
	switch (action.type) {
		case ActionTypes.SET_ALL_INDUSTRIES:
			return { ...state, industries: action.payload };
		case ActionTypes.SET_NEW_INDUSTRY:
			tempIndustry = [...state.industries];
			index = tempIndustry.findIndex((ind) => ind.id === action.payload.id);
			if (index !== -1) {
				tempIndustry[index] = action.payload;
			} else {
				tempIndustry.push(action.payload);
			}
			return { ...state, industries: tempIndustry };
		case ActionTypes.REMOVE_INDUSTRY:
			tempIndustry = [...state.industries];
			index = tempIndustry.findIndex((ind) => ind.url === action.payload);
			tempIndustry.splice(index, 1);
			return { ...state, industries: tempIndustry };

		case ActionTypes.LOADING_INDUSTRY:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_INDUSTRY:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
