import React from 'react';
// import NotFound from "../components/NotFound/NotFound";
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CFade } from '@coreui/react';

//redux
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, role, user, isLogged, ...rest }) => {
	// and that's it.
	return (
		<Route
			{...rest}
			render={(props) => {
				if (!isLogged) {
					return (
						<CFade>
							<Redirect to="/login" />;
						</CFade>
					);
				}
				if (user && user.roles) {
					if (!role || user.roles.includes('super_user')) return <Component {...props} />;

					if (user.roles.indexOf(role) === -1) {
						return <Redirect to="/notfound" />;
					}
					return <Component {...props} />;
				}
			}}
		/>
	);
};

const AuthRoute = ({ component: Component, isLogged, ...rest }) => (
	<Route {...rest} render={(props) => (!isLogged === true ? <Component {...props} /> : <Redirect to="/" />)} />
);

PrivateRoute.propTypes = {
	isLogged: PropTypes.bool,
};

AuthRoute.propTypes = {
	isLogged: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	isLogged: state.user.isLogged,
});

const ContainerCreater = connect(mapStateToProps);

const FirstConnectedComponent = ContainerCreater(PrivateRoute);
const SecondConnectedComponent = ContainerCreater(AuthRoute);

export { FirstConnectedComponent as PrivateRoute, SecondConnectedComponent as AuthRoute };
