import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	chalans: [],
};

export default function (state = initialState, action) {
	let tempChalan, index;
	switch (action.type) {
		case ActionTypes.SET_ALL_CHALANS:
			return { ...state, chalans: action.payload };
		case ActionTypes.SET_NEW_CHALAN:
			tempChalan = [...state.chalans];
			index = tempChalan.findIndex((chalan) => chalan.id === action.payload.id);
			if (index !== -1) {
				tempChalan[index] = action.payload;
			} else {
				tempChalan.unshift(action.payload);
			}
			return { ...state, chalans: tempChalan };
		case ActionTypes.REMOVE_CHALAN:
			tempChalan = [...state.chalans];
			index = tempChalan.findIndex((chalan) => chalan.url === action.payload);
			tempChalan.splice(index, 1);
			return { ...state, chalans: tempChalan };
		case ActionTypes.LOADING_CHALAN:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_CHALAN:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
