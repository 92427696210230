import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: true,
	closingStock: [],
	totalResults: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_ALL_CLOSING_STOCK:
			return { ...state, closingStock: action.payload.results, totalResults: action.payload.count };
		case ActionTypes.POST_CLOSING_STOCK:
			return {
				...state,
				closingStock: [action.payload, ...state.closingStock],
				totalResults: state.totalResults + 1,
			};
		case ActionTypes.DELETE_CLOSING_STOCK:
			const tempLedger = [...state.closingStock];
			const index = tempLedger.findIndex((group) => group.id === action.payload);
			let totalClosingStock = state.totalResults;

			if (index !== -1) {
				tempLedger.splice(index, 1);
				totalClosingStock = totalClosingStock - 1;
			}
			return { ...state, closingStock: tempLedger, totalResults: totalClosingStock };

		default:
			return state;
	}
}
