import * as ActionTypes from '../ActionTypes';

const initialState = {
	loading: false,
	trialbalance: {},
	balancesheet: {},
	ledgerReport: {},
	incomestatement: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_TRIALBALANCE_DATA:
			return { ...state, trialbalance: action.payload };
		case ActionTypes.SET_BALANCESHEET_DATA:
			return { ...state, balancesheet: action.payload };
		case ActionTypes.SET_INCOME_STATEMENT:
			return { ...state, incomestatement: action.payload };

		case ActionTypes.SET_LEDGER_DATA:
			return { ...state, ledgerReport: action.payload };
		case ActionTypes.LOADING_BALANCE_SHEET:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_BALANCE_SHEET:
			return {
				...state,
				loading: false,
			};
		case ActionTypes.LOADING_TRIALBALANCE:
			return {
				...state,
				loading: true,
			};
		case ActionTypes.STOP_LOADING_TRIALBALANCE:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
